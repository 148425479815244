<template>
  <div class="tiptap-editor">
    <slot name="header" />

    <EditorMenuBar
      v-if="editable && actions.length"
      v-slot="{ commands, isActive }"
      :editor="editor"
    >
      <div class="menubar">
        <template v-for="editorOption in computedActions">
          <button
            :key="editorOption.name"
            type="button"
            :class="{
              'is-active': isActive[editorOption.tiptapName](
                editorOption.config
              )
            }"
            :title="editorOption.title"
            @click="
              evt => {
                commands[editorOption.tiptapName](editorOption.config)
              }
            "
            v-html="$sanitize(editorOption.icon)"
          />
        </template>
      </div>
    </EditorMenuBar>
    <main class="tiptap-editor__content">
      <EditorContent
        :editor="editor"
        class="tiptap-editor__content--editor"
        :spellcheck="spellcheck"
      />

      <div class="tiptap-editor__appended-content">
        <slot name="content.append" />
      </div>
    </main>
    <footer class="tiptap-editor__footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap/dist/tiptap.min'
import {
  Bold,
  Blockquote,
  BulletList,
  HardBreak,
  Heading,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Strike,
  Underline,
  Placeholder
} from 'tiptap-extensions/dist/extensions.min'

export default {
  name: 'TiptapEditor',
  components: {
    EditorContent,
    EditorMenuBar
  },
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: null
    }
  },
  data: () => ({
    editor: null,
    extensions: {
      bold: {
        tiptapName: 'bold',
        icon: '<b>B<b>'
      },
      italic: {
        tiptapName: 'italic',
        icon: '<i>I<i>'
      },
      underline: {
        tiptapName: 'underline',
        icon: '<u>U<u>'
      },
      blockquote: {
        tiptapName: 'blockquote',
        icon: '“ ”'
      },
      ordered_list: {
        tiptapName: 'ordered_list',
        icon: '#'
      },
      bullet_list: {
        tiptapName: 'bullet_list',
        icon: '•'
      },
      strike: {
        tiptapName: 'strike',
        icon: '<s>S</s>'
      },
      heading1: {
        tiptapName: 'heading',
        icon: '<b>H1</b>',
        config: { level: 1 }
      },
      heading2: {
        tiptapName: 'heading',
        icon: '<b>H2</b>',
        config: { level: 2 }
      }
    },
    spellcheck: false
  }),
  computed: {
    computedActions() {
      const extensions = this.extensions
      return this.actions
        .filter(
          option =>
            (option.name && Object.keys(extensions).includes(option.name)) ||
            Object.keys(this.extensions).includes(option)
        )
        .map(action =>
          action.name
            ? {
                ...extensions[action.name],
                ...action
              }
            : {
                ...extensions[action],
                name: action
              }
        )
    }
  },
  watch: {
    editable: function(newVal) {
      this.editor.setOptions({
        editable: newVal
      })

      if (this.editor.getHTML() !== this.value) {
        this.editor.setContent(this.value)
      }
    },
    value: function(newVal) {
      if (this.editor.getHTML() !== newVal) {
        this.editor.setContent(newVal)
      }
    }
  },
  created() {
    if (window.spellcheck) {
      this.spellcheck = true
    }

    this.editor = new Editor({
      autoFocus: false,
      editable: this.editable,
      disablePasteRules: true, // disable Markdown when pasting
      disableInputRules: true, // disable Markdown when typing
      extensions: [
        new Bold(),
        new Blockquote(),
        new Italic(),
        new Underline(),
        new Strike(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new Placeholder({ emptyNodeText: this.placeholder }),
        new Link()
      ],
      content: this.value,
      onUpdate: ({ getHTML }) => {
        this.$emit('input', getHTML())
        this.$emit('change', getHTML())
      }
    })
  },
  mounted() {
    if (this.height) {
      const el = this.$el.querySelector(
        '.tiptap-editor tiptap-editor__content--editor .ProseMirror'
      )
      if (el !== null) {
        el.style.height = this.height
      }
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  }
}
</script>
<style lang="scss">
@import 'TiptapEditor';
</style>
