var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tiptap-editor"},[_vm._t("header"),(_vm.editable && _vm.actions.length)?_c('EditorMenuBar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('div',{staticClass:"menubar"},[_vm._l((_vm.computedActions),function(editorOption){return [_c('button',{key:editorOption.name,class:{
            'is-active': isActive[editorOption.tiptapName](
              editorOption.config
            )
          },attrs:{"type":"button","title":editorOption.title},domProps:{"innerHTML":_vm._s(_vm.$sanitize(editorOption.icon))},on:{"click":function (evt) {
              commands[editorOption.tiptapName](editorOption.config)
            }}})]})],2)]}}],null,false,3066835518)}):_vm._e(),_c('main',{staticClass:"tiptap-editor__content"},[_c('EditorContent',{staticClass:"tiptap-editor__content--editor",attrs:{"editor":_vm.editor,"spellcheck":_vm.spellcheck}}),_c('div',{staticClass:"tiptap-editor__appended-content"},[_vm._t("content.append")],2)],1),_c('footer',{staticClass:"tiptap-editor__footer"},[_vm._t("footer")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }